import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import { store, persistor } from './redux/store'

import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<React.Fragment>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
</React.Fragment>);

reportWebVitals();
