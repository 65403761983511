import {
    ADD_GROUP_TO_VISIBLE,
    GET_GRAPH_ERROR,
    GET_GRAPH_REQUEST,
    GET_GRAPH_SUCCESS,
    REMOVE_GROUP_FROM_VISIBLE,
    SET_DAG_MODE,
    SET_SELECTED_GROUP,
    SET_SELECTED_NODE_ID
} from "../actions/graph";
import {defaultVisibleNodes} from "../../const";

const initialState = {
    graphData: null,
    loadingGraph: false,
    selectedNodeId: '',
    selectedGroup: '',
    dagMode: null,
    visibleGroups: defaultVisibleNodes
}

export default function graphReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_GRAPH_REQUEST: {
            return {
                ...state,
                loadingGraph: true,
            }
        }

        case GET_GRAPH_SUCCESS:

            return {
                ...state,
                graphData: payload,
                loadingGraph: false,
            }

        case GET_GRAPH_ERROR:
            return {
                ...state,
                loadingGraph: false,
            }

        case SET_SELECTED_NODE_ID:
            return {
                ...state,
                selectedNodeId: payload
            }

        case SET_SELECTED_GROUP:
            return {
                ...state,
                selectedGroup: payload
            }

        case SET_DAG_MODE:
            return {
                ...state,
                dagMode: payload
            }

        case ADD_GROUP_TO_VISIBLE:
            return {
                ...state,
                visibleGroups: [...state.visibleGroups, payload]
            }

        case REMOVE_GROUP_FROM_VISIBLE:
            return {
                ...state,
                visibleGroups: state.visibleGroups.filter(group => group !== payload)
            }

        default:
            return state
    }
}
