import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import SearchInput from "./SearchInput";
import PropTypes from "prop-types";
import {SET_DAG_MODE, SET_SELECTED_GROUP, SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import {connect} from "react-redux";

const NabBar = ({ setDimensional, orbiting, handleOrbiting, setDagMode, loadingGraph }) => {

    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState('ru')

    useEffect(()=>{
        i18n.changeLanguage(lang);
    },[])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLang(lng)
    };

    const changeDagMode = (mode) => {
        setDagMode(mode)
    };

    return (
        // eslint-disable-next-line react/jsx-no-undef
        <React.Fragment>
            <div className="w-full navbar bg-transparent space-x-3 flex flex-row">
                <a className="btn btn-ghost normal-case text-lg pl-2">{t('title.project')}</a>
                <div className={"flex-1"}>
                    <SearchInput />
                </div>
                {/*<select*/}
                {/*    className="select select-bordered select-sm text-sm"*/}
                {/*    defaultValue={''}*/}
                {/*    onChange={(event) => {*/}
                {/*        changeDagMode(event.target.value)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <option disabled value={''}>{"Выберите режим"}</option>*/}
                {/*    <option value={'off'}>Off</option>*/}
                {/*    <option value={'td'}>{"top-down"}</option>*/}
                {/*    <option value={'bu'}>{"bottom-up"}</option>*/}
                {/*    <option value={'lr'}>{"left-to-right"}</option>*/}
                {/*    <option value={'rl'}>{"right-to-left"}</option>*/}
                {/*    <option value={'zout'}>{"near-to-far"}</option>*/}
                {/*    <option value={'zin'}>{"far-to-near"}</option>*/}
                {/*    <option value={'radialout'}>{"outwards-radially"}</option>*/}
                {/*    <option value={'radialin'}>{"inwards-radially"}</option>*/}
                {/*</select>*/}
                <div className={"space-x-3"}>
                    <select
                        className="select select-bordered select-sm"
                        disabled={loadingGraph}
                        onChange={(event) => {
                            if(orbiting === true) {
                                handleOrbiting()
                            }
                            setDimensional(event.target.value)
                        }}
                    >
                        <option value={'3d'}>3D</option>
                        <option value={'2d'}>2D</option>
                    </select>
                    {/*{dimensional === '3d' ? (*/}
                    {/*    <>*/}
                    {/*        <div className="form-control">*/}
                    {/*            <label className="label cursor-pointer space-x-2">*/}
                    {/*                <span className="label-text">{t('navbar.orbiting')}</span>*/}
                    {/*                <input*/}
                    {/*                    type="checkbox"*/}
                    {/*                    checked={orbiting}*/}
                    {/*                    className="checkbox checkbox-sm"*/}
                    {/*                    onChange={handleOrbiting}*/}
                    {/*                />*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <button*/}
                    {/*            disabled={!orbiting}*/}
                    {/*            className={"btn btn-circle btn-xs"}*/}
                    {/*            onClick={zoomIn}*/}
                    {/*        >*/}
                    {/*            +*/}
                    {/*        </button>*/}
                    {/*        <button*/}
                    {/*            disabled={!orbiting}*/}
                    {/*            className={"btn btn-circle btn-xs"}*/}
                    {/*            onClick={zoomOut}*/}
                    {/*        >*/}
                    {/*            -*/}
                    {/*        </button>*/}
                    {/*    </>*/}
                    {/*) : null}*/}
                    <select
                        className="select select-bordered select-sm text-sm"
                        disabled={loadingGraph}
                        onChange={(event) => {
                            changeLanguage(event.target.value)
                        }}
                    >
                        <option className={"text-sm"} value={'ru'}>{t('language.ru').toUpperCase()}</option>
                        <option className={"text-sm"} value={'en'}>{t('language.en').toUpperCase()}</option>
                    </select>
                </div>
            </div>
        </React.Fragment>
    )
}

NabBar.propTypes = {
    setDagMode: PropTypes.func,
    loadingGraph: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    setDagMode: (mode) => dispatch({ type: SET_DAG_MODE, payload: mode }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NabBar)