import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const ReadMoreLink = ({ content, setModalContent, setOpenModal }) => {

    const { t } = useTranslation();

    return (
       <React.Fragment>
           <div
               className="text-blue-600 after:content-['_↗'] cursor-pointer"
               onClick={()=>{
                   setModalContent(content)
                   setOpenModal(true)
               }}
           >
               {t('button.readMore')}
           </div>
       </React.Fragment>
    )
}



ReadMoreLink.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ReadMoreLink)