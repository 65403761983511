import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {defaultVisibleNodes, legend} from "../../const";
import {
    SET_SELECTED_GROUP,
    SET_SELECTED_NODE_ID
} from "../../redux/actions/graph";
import ForceGraph3D from "react-force-graph-3d";
import ForceGraph2D from "react-force-graph-2d";
import NodeCard from "./NodeCard";

const NetworkGraph = ({
                          orbiting,
                          handleNodeInfo,
                          dimensional = "3d",
                          cameraDistance,
                          graphData,
                          selectedNodeId,
                          selectedGroup,
                          setSelectedGroup,
                          setSelectedNodeId,
                          visibleGroups
}) => {

    const NODE_R = 3;

    const graph2DRef = useRef();
    const graph3DRef = useRef();
    const graphLayoutRef = useRef();

    const [highlightNodes, setHighlightNodes] = useState(new Set());
    const [highlightLinks, setHighlightLinks] = useState(new Set());
    const [graphLayoutSize, setGraphLayoutSize] = useState(null);
    const [hoverNode, setHoverNode] = useState(null);

    function findColorByKey(key) {
        const foundItem = legend.find((item) => item.code === key);
        return foundItem ? foundItem.color : "gray";
    }

    const setColor = (node, highlightNodes, hoverNode) => {
        // return highlightNodes.has(node) ? node === hoverNode ? 'rgb(255,0,0,1)' : findColorByKey(node.group) : findColorByKey(node.group)
        return findColorByKey(node.group)
    }

    const ensureArrayPropertyExists = (obj, propName) => {
        if (!obj[propName]) {
            obj[propName] = [];
        }
    };

    useEffect(() => {
        if (graphData) {
            graphData.links.forEach((link) => {
                const a = graphData.nodes.find((node) => node.id === link.source);
                const b = graphData.nodes.find((node) => node.id === link.target);

                ensureArrayPropertyExists(a, "neighbors");
                ensureArrayPropertyExists(b, "neighbors");
                a.neighbors.push(b);
                b.neighbors.push(a);

                ensureArrayPropertyExists(a, "links");
                ensureArrayPropertyExists(b, "links");
                a.links.push(link);
                b.links.push(link);
            });
        }
    }, [graphData]);

    const handleClick = useCallback((node) => {
        handleNodeInfo(node);
    }, [handleNodeInfo]);

    const handleRightClick = useCallback((node) => {
        const distance = 40;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

        graph3DRef.current.cameraPosition(
            { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio },
            node,
            3000
        );

        handleNodeInfo(node);
    }, [graph3DRef]);

    const updateHighlight = () => {
        setHighlightNodes(highlightNodes);
        setHighlightLinks(highlightLinks);
    };

    const handleNodeHover = (node) => {
        highlightNodes.clear();
        highlightLinks.clear();

        setSelectedNodeId("");
        setSelectedGroup("");

        if (node) {
            highlightNodes.add(node);
            if (node.neighbors) {
                node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
            }

            if (node.links) {
                node.links.forEach((link) => highlightLinks.add(link));
            }
        }

        setHoverNode(node || null);
        updateHighlight();
    };

    const handleGroupHover = (nodes) => {
        highlightNodes.clear();
        highlightLinks.clear();

        setSelectedNodeId("");
        setSelectedGroup("");

        if (nodes && nodes.length > 0) {
            nodes.forEach((node) => highlightNodes.add(node));
            setHighlightNodes(highlightNodes);
        }
    };

    const handleLinkHover = (link) => {
        highlightNodes.clear();
        highlightLinks.clear();

        if (link) {
            highlightLinks.add(link);
            highlightNodes.add(link.source);
            highlightNodes.add(link.target);
        }

        updateHighlight();
    };

    const handleVisibility = (node) => {
        return visibleGroups.includes(node.group)
    }


    const paintRing = useCallback((node, ctx) => {
        ctx.beginPath();
        ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
        ctx.fillStyle = node === hoverNode ? "red" : "orange";
        ctx.fill();
    }, [hoverNode]);

    useEffect(() => {
        let intervalId;
        const startOrbit = () => {
            graph3DRef.current.cameraPosition({ z: cameraDistance });

            let angle = 0;
            intervalId = setInterval(() => {
                graph3DRef.current.cameraPosition({
                    x: cameraDistance * Math.sin(angle),
                    z: cameraDistance * Math.cos(angle),
                });
                angle += Math.PI / 300;
            }, 10);
        };

        if (graph3DRef.current !== undefined) {
            if (orbiting) {
                startOrbit();
            } else {
                clearInterval(intervalId);
            }
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [cameraDistance, graph3DRef, orbiting]);

    useEffect(() => {
        if (graphLayoutRef.current !== undefined) {
            const element = graphLayoutRef.current;
            const rect = element.getBoundingClientRect();

            const width = rect.width;
            const height = rect.height;

            setGraphLayoutSize({
                width: width,
                height: height,
            });
        }
    }, [graphLayoutRef]);

    useEffect(() => {
        if (graphData) {
            if (selectedNodeId !== "") {
                const node = graphData.nodes.find((node) => node.id === selectedNodeId);
                if (node) {
                    handleNodeHover(node);
                }
            }

            if (selectedGroup !== "") {
                const nodes = graphData.nodes.filter((node) => node.group === selectedGroup);
                handleGroupHover(nodes);
            }
        }
    }, [graphData, selectedGroup, selectedNodeId]);

    return (
        <div ref={graphLayoutRef} className={"flex w-full h-full relative"}>
            {graphLayoutSize && graphData !== null && (
                <>
                    <NodeCard node={hoverNode} />
                    {dimensional === "3d" ? (
                        <ForceGraph3D
                            ref={graph3DRef}
                            width={graphLayoutSize.width}
                            height={graphLayoutSize.height}
                            graphData={graphData}
                            nodeRelSize={NODE_R}
                            nodeVal={(node) => (highlightNodes.has(node) ? 10 : 2)}
                            autoPauseRedraw={false}
                            linkWidth={(link) => (highlightLinks.has(link) ? 2 : 1)}
                            linkDirectionalParticles={4}
                            linkDirectionalParticleWidth={(link) => (highlightLinks.has(link) ? 3 : 0)}
                            nodeCanvasObjectMode={(node) => (highlightNodes.has(node) ? "before" : undefined)}
                            nodeCanvasObject={paintRing}
                            onNodeClick={handleClick}
                            onNodeRightClick={handleRightClick}
                            onNodeDragEnd={(node) => {
                                node.fx = node.x;
                                node.fy = node.y;
                                node.fz = node.z;
                            }}
                            onNodeHover={handleNodeHover}
                            onLinkHover={handleLinkHover}
                            backgroundColor={"rgb(255, 255, 255, 255)"}
                            nodeLabel={(node) => `<span style="color: #000000">${node.label}</span>`}
                            nodeColor={(node) => setColor(node, highlightNodes, hoverNode)}
                            nodeVisibility={handleVisibility}
                        />
                    ) : (
                        <ForceGraph2D
                            ref={graph2DRef}
                            graphData={graphData}
                            nodeRelSize={NODE_R}
                            autoPauseRedraw={false}
                            width={graphLayoutSize.width}
                            height={graphLayoutSize.height}
                            linkWidth={(link) => (highlightLinks.has(link) ? 2 : 1)}
                            linkDirectionalParticles={4}
                            linkDirectionalParticleWidth={(link) => (highlightLinks.has(link) ? 3 : 0)}
                            nodeCanvasObjectMode={(node) => (highlightNodes.has(node) ? "before" : undefined)}
                            nodeCanvasObject={paintRing}
                            nodeAutoColorBy="group"
                            onNodeClick={handleClick}
                            onNodeDragEnd={(node) => {
                                node.fx = node.x;
                                node.fy = node.y;
                                node.fz = node.z;
                            }}
                            onNodeHover={handleNodeHover}
                            onLinkHover={handleLinkHover}
                            backgroundColor={"rgb(255, 255, 255, 255)"}
                            nodeLabel={(node) => `<span style="color: white;">${node.label}</span>`}
                            nodeColor={setColor}
                        />
                    )}
                </>
            )}
        </div>
    );
};

NetworkGraph.propTypes = {
    graphData: PropTypes.object,
    visibleGroups: PropTypes.array,
    selectedNodeId: PropTypes.string,
    selectedGroup: PropTypes.string,
    dagMode: PropTypes.string,
    setSelectedGroup: PropTypes.func,
    setSelectedNodeId: PropTypes.func,
};

const mapStateToProps = ({ graph }) => ({
    graphData: graph.graphData,
    selectedNodeId: graph.selectedNodeId,
    selectedGroup: graph.selectedGroup,
    dagMode: graph.dagMode,
    visibleGroups: graph.visibleGroups
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedGroup: (group) => dispatch({ type: SET_SELECTED_GROUP, payload: group }),
    setSelectedNodeId: (id) => dispatch({ type: SET_SELECTED_NODE_ID, payload: id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkGraph);