import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'
import {GET_GRAPH, GET_GRAPH_ERROR, GET_GRAPH_REQUEST, GET_GRAPH_SUCCESS} from "../actions/graph";
import graphApi from "../api/graph";


function* getGraph() {

    yield put({ type: GET_GRAPH_REQUEST})

    const response = yield call(graphApi.getGraph)

    if(response.status === 200) {
        yield put({type: GET_GRAPH_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({type: GET_GRAPH_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_GRAPH_ERROR, payload: response.status })
    }

}

export default function* graphSaga() {
    yield takeLatest(GET_GRAPH, getGraph)
}
