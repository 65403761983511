import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {HiOutlineViewfinderCircle} from "react-icons/hi2";
import {SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import PropTypes from "prop-types";

const NodeHeader = ({ title, group, nodeId, setSelectedNodeId }) => {

    const { t } = useTranslation();

    return (
       <React.Fragment>
           <div className={"flex flex-col px-4 "}>
               <div className={"flex flex-row items-center pb-4 space-x-3"}>
                   <div className={"text-xl"}>{t(`node.${group}.name`)}</div>
                   <div
                       className={"cursor-pointer"}
                       onClick={()=>{
                           const id = nodeId.replaceAll('-', '')
                           setSelectedNodeId(id)
                       }}
                   >
                       <HiOutlineViewfinderCircle className={"h-6 w-6"}/>
                   </div>
               </div>
               <div className={"text-base w-11/12"}>
                   {title}
               </div>
           </div>
       </React.Fragment>
    )
}



NodeHeader.propTypes = {
    nodeId: PropTypes.string,
    title: PropTypes.any,
    group: PropTypes.string,
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeHeader)