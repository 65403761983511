import React, {useEffect, useState} from 'react';
import shortid from "shortid";
import {RiTeamLine} from "react-icons/ri";
import PropTypes from "prop-types";
import {SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {GoOrganization} from "react-icons/go";


const ProductNodeBody = ({ content, setSelectedNodeId }) => {

    const { t } = useTranslation();

    const [authors, setAuthors] = useState([])
    const [stakeholders, setStakeholders] = useState([])

    console.log(content)

    useEffect(() => {
        if(content){
            setAuthors(content.instance.authors)
        }
    }, [authors, content]);

    useEffect(() => {
        if(content){
            setStakeholders(content.instance.stakeholders)
        }
    }, [content, stakeholders]);

    return (
        <React.Fragment >
            <div className={"flex flex-col space-y-2"}>
                {authors.length > 0 && (
                    <>
                        <ul className="pl-0 py-0 w-full menu bg-base-200 ounded-box space-x-1">
                            <li className={"w-full space-x-1"} key={shortid.generate()}>
                                <details className={"w-full"}>
                                    <summary
                                        className={`custom-summary w-full active items-center space-x-1 bg-transparent hover:bg-transparent`}
                                    >
                                        <RiTeamLine />
                                        {t('node.product.authors')}
                                    </summary>
                                    <ul className={"space-x-1"}>
                                        {authors.map(author => {

                                            return (

                                                <li
                                                    className={"w-full space-x-1"}
                                                    key={shortid.generate()}
                                                    onMouseEnter={()=>{
                                                        const id = author.id.replaceAll('-', '')
                                                        setSelectedNodeId(id)
                                                    }}
                                                >
                                                    <a
                                                        className={"w-full"}>
                                                        <p className={"w-full break-words whitespace-normal"}>
                                                            {`${author.lastName} ${author.firstName}`}
                                                        </p>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </details>
                            </li>
                        </ul>
                    </>
                )}
                {stakeholders.length > 0 && (
                    <>
                        <ul className="pl-0 py-0 w-full menu bg-base-200 ounded-box space-x-1">
                            <li className={"w-full space-x-1"} key={shortid.generate()}>
                                <details className={"w-full"}>
                                    <summary
                                        className={`custom-summary w-full active items-center space-x-1 bg-transparent hover:bg-transparent`}
                                    >
                                        <GoOrganization />
                                        {t('node.product.stakeholders')}
                                    </summary>
                                    <ul className={"space-x-1"}>
                                        {stakeholders.map(stakeholder => {
                                            return (
                                                <li
                                                    className={"w-full space-x-1"}
                                                    key={shortid.generate()}
                                                    onMouseEnter={()=>{
                                                        const id = stakeholder.id.replaceAll('-', '')
                                                        setSelectedNodeId(id)
                                                    }}
                                                >
                                                    <a
                                                        className={"w-full"}>
                                                        <p className={"w-full break-words whitespace-normal"}>
                                                            {`${stakeholder.title}`}
                                                        </p>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </details>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <div className={"divider"} />
        </React.Fragment>
    )
}

ProductNodeBody.propTypes = {
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductNodeBody)