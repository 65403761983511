
export const checkIsClamped = (ref) => {
    console.log(ref.current.scrollHeight, ref.current.clientHeight)
    return ref.current.scrollHeight > ref.current.clientHeight
}

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

export function removeCyclicReferences(obj) {
    const seen = new WeakSet(); // Используем WeakSet для отслеживания уже просмотренных объектов

    function clean(obj) {
        if (typeof obj === 'object' && obj !== null) {
            if (seen.has(obj)) {
                return; // Объект уже был обработан, пропускаем его
            }
            seen.add(obj);

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    clean(obj[key]); // Рекурсивно очищаем вложенные объекты
                }
            }
        }
    }

    clean(obj);
}