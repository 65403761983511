import React from "react";

const Loading = ({ size="lg", isFullScreen= false }) => {

    return (
        <>
            <span className={`loading loading-spinner loading-${size}`} />
        </>
    )
};

export default Loading;