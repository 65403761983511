import React from 'react'
import {
    Routes,
    Route,
} from "react-router-dom";

import {connect} from "react-redux";

import Graph from "./Graph";



const Main = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Graph />} />
        </Routes>
    )
}

Main.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)


