import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {SET_SELECTED_NODE_ID} from "../../../redux/actions/graph";
import {connect} from "react-redux";
import MediaTile from "./MediaTile";
import shortid from "shortid";
import {RiTeamLine} from "react-icons/ri";

const MediaSection = ({ media }) => {

    const { t } = useTranslation();

    if(media.length < 6) {
        return (
            <React.Fragment>
                <div className={"px-4"}>
                    <div className={"text-xl pb-4"}>{t('node.section.media.title')}</div>
                    <div className={"flex flex-row flex-wrap w-full gap-4"}>
                        {media && media.map((node) => {
                            return <MediaTile key={shortid.generate()} instance={node.instance} />
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    } else if(media.length > 5) {
        return (
            <React.Fragment>
                <ul className="pl-0 py-0 w-full menu bg-base-200 ounded-box space-x-1">
                    <li className={"w-full space-x-1"} key={shortid.generate()}>
                        <details className={"w-full"}>
                            <summary
                                className={`custom-summary w-full items-center bg-transparent hover:bg-transparent pb-4`}
                            >
                                <div className={"text-xl"}>{t('node.section.media.title')}</div>
                                <div className={"badge badge-outline"}>{media.length}</div>
                            </summary>
                            <ul className={"space-x-1"}>
                                {media.map(node => {
                                    return <MediaTile key={shortid.generate()} instance={node.instance} />
                                })}
                            </ul>
                        </details>
                    </li>
                </ul>
            </React.Fragment>
        )
    }  else return null
}

MediaSection.propTypes = {
    media: PropTypes.array
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaSection)