export const GET_GRAPH = "GET_GRAPH"
export const GET_GRAPH_REQUEST = "GET_GRAPH_REQUEST"
export const GET_GRAPH_SUCCESS = "GET_GRAPH_SUCCESS"
export const GET_GRAPH_ERROR = "GET_GRAPH_ERROR"

export const SET_SELECTED_NODE_ID = "SET_SELECTED_NODE_ID"

export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP"

export const SET_DAG_MODE = "SET_DAG_MODE"
export const ADD_GROUP_TO_VISIBLE = "ADD_GROUP_TO_VISIBLE"
export const REMOVE_GROUP_FROM_VISIBLE = "REMOVE_GROUP_FROM_VISIBLE"