import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_SELECTED_GROUP} from "../../redux/actions/graph";

const LegendTile = ({color, code, group, setSelectedGroup, selectedGroup, setGroup, disabled }) => {

    const { t } = useTranslation();

    const [highlight, setHighlight] = useState(false)

    useEffect(()=>{
        if(group && code){
            setHighlight(group === code)
        }
    },[group, code, selectedGroup])

    return (
        <React.Fragment >
            <div
                className={`rounded-box flex w-full items-center space-x-2 p-3 ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'} cursor-pointer ${highlight && 'bg-[#34495E] opacity-70'}`}
                onClick={()=>{
                    if(!disabled){
                        setSelectedGroup(code)
                        setGroup(code)
                    }
                }}
            >
                <div
                    className={`w-4 h-4 rounded-full`}
                    style={{backgroundColor: color}}
                />
                <span className={`${highlight && 'text-white'} text-xs`}>{t(`node.${code}.name`)}</span>
            </div>
        </React.Fragment>
    )
}

LegendTile.propTypes = {
    selectedGroup: PropTypes.string,
    setSelectedGroup: PropTypes.func,
}

const mapStateToProps = (state) => ({
    selectedGroup: state.graph.selectedGroup
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedGroup: (group) => dispatch({ type: SET_SELECTED_GROUP, payload: group }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LegendTile)