import {useTranslation} from "react-i18next";
import RelationTitle from "./RelationTile";
import shortid from "shortid";
import React from "react";
import {
    FiAlertTriangle,
    FiCalendar,
    FiMessageSquare,
    FiSettings,
    FiShoppingCart,
    FiSliders, FiTool,
    FiUsers
} from "react-icons/fi";
import {AiOutlineExperiment} from "react-icons/ai";
import {GoOrganization} from "react-icons/go";
import {MdOutlineSpatialAudioOff, MdSpatialAudioOff} from "react-icons/md";
import {GiPathDistance} from "react-icons/gi";
import {PiPathDuotone, PiPathFill} from "react-icons/pi";

const RelationSection = ({relationByGroup}) => {

    const { t } = useTranslation();
    const categories = Object.keys(relationByGroup)

    const excluded = ['media']

    return (
        <React.Fragment>
            <div className={"px-4"}>
                <div className={"text-xl"}>{t('node.section.relation.title')}</div>
                <ul className="pl-0 w-full menu bg-base-200 ounded-box">
                    {categories.map((category, index) => {
                        if (relationByGroup[category] && !excluded.includes(category)) {
                            return (
                                <li className={"w-full"} key={shortid.generate()}>
                                    <details className={"w-full"}>
                                        <summary className={"w-full space-x-1 items-center"}>
                                            {
                                                {
                                                    event: <FiCalendar className={"w-4 h-4"}/>,
                                                    person: <FiUsers className={"w-4 h-4"}/>,
                                                    expertisearea: <AiOutlineExperiment className={"w-4 h-4"}/>,
                                                    speechtopic: <MdOutlineSpatialAudioOff className={"w-4 h-4"}/>,
                                                    expression: <FiMessageSquare className={"w-4 h-4"}/>,
                                                    expressiontype: <FiSettings className={"w-4 h-4"}/>,
                                                    organisation: <GoOrganization className={"w-4 h-4"}/>,
                                                    organisationtype: <FiSettings className={"w-4 h-4"}/>,
                                                    problem: <FiAlertTriangle className={"w-4 h-4"}/>,
                                                    product: <FiShoppingCart className={"w-4 h-4"}/>,
                                                    speciality: <FiSliders className={"w-4 h-4"}/>,
                                                    tool: <FiTool className={"w-4 h-4"}/>,
                                                    solutionpath: <PiPathDuotone className={"w-4 h-4"}/>
                                                }[category]
                                            }
                                            {t(`node.${category}.name`)}
                                            <span className={"badge badge-xs py-2 badge-outline"}>{relationByGroup[category]?.length}</span>
                                        </summary>
                                        <ul className={"w-full"}>
                                            {relationByGroup[category].map(node => {
                                                return (<RelationTitle key={shortid.generate()} instance={node.instance} category={category} />)
                                            })}
                                        </ul>
                                    </details>
                                </li>
                            );
                        } else {
                            return null; // или другой вариант обработки, если данные отсутствуют
                        }
                    })}
                </ul>
            </div>
        </React.Fragment>
    )
}

export default RelationSection