// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from 'redux'

// Reducers
import auth from './auth'
import graph from "./graph";
import common from "./common"

export default combineReducers({
    auth,
    graph,
    common
})
