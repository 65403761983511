import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import ExpressionNodeBody from "./ExpressionNodeBody";
import EventNodeBody from "./EventNodeBody";
import ToolNodeBody from "./ToolNodeBody";
import ProductNodeBody from "./ProductNodeBody";
import PersonNodeBody from "./PersonNodeBody";
import MediaSection from "./components/MediaSection";
import RelationSection from "./components/RelationSection";
import NodeHeader from "./NodeHeader";
import NodeDescription from "./NodeDescription";
import {isObjectEmpty} from "../../helpers";
import {SlSocialVkontakte} from "react-icons/sl";
import {LiaTelegramPlane} from "react-icons/lia";
import {TbWorld} from "react-icons/tb";

import {SET_MEDIA_INFO_WIDTH, SET_MODAL_CONTENT, SET_OPEN_MODAL} from "../../redux/actions/common";


const LeftSideBar = ({ content, setMediaInfoWidth }) => {

    const { t } = useTranslation();

    const leftSideBarRef = useRef()

    const [relationByGroup, setRelationByGroup] = useState({})
    const [media, setMedia] = useState([])
    const [title, setTitle] = useState('')

    useEffect(() => {
        if(leftSideBarRef.current !== undefined){
            const boundingClientRect = leftSideBarRef.current.getBoundingClientRect()
            const width = boundingClientRect.width
            const padding = width * 0.1
            setMediaInfoWidth(width - padding)
        }
    }, [leftSideBarRef]);

    useEffect(() => {
        if(content){
            const neighbors = content.neighbors
            const groups = {}

            let lastName
            let firstName
            let middleName

            if(neighbors !== undefined) {
                for (let i = 0; i < neighbors.length; i++) {
                    const category = neighbors[i].group

                    if (!groups.hasOwnProperty(category)) {
                        groups[category] = []
                    }
                    groups[category].push(neighbors[i])
                }
            }

            setRelationByGroup(groups)

            if (content.group === 'media') {
                setMedia([content])
            } else {
                setMedia(groups['media'])
            }

            if (content.group === 'person') {
                lastName = content.instance.lastName
                firstName = content.instance.firstName
                middleName = content.instance.middleName
                setTitle(lastName ? `${lastName} ${firstName} ${middleName}` : content.instance.email)
            } else if (content.group === 'expression') {
                if(content.instance.author) {
                    lastName = content.instance.author.lastName
                    firstName = content.instance.author.firstName
                    const author = lastName ? `${lastName} ${firstName}` : `${content.instance.author.email}`
                    setTitle(
                        <div className={"flex flex-col w-full"}>
                            <div className={"uppercase"}>{content.instance.type.title}</div>
                            {author && <div>{ t('node.expression.author', {author: author}) }</div>}
                        </div>
                    )
                } else {
                    setTitle(`${content.instance.type.title}`)
                }
            } else if (content.group === 'organisation') {
                setTitle(
                    <div className={"flex flex-col w-full space-y-3"}>
                        <div className={"flex flex-col space-y-2"}>
                            {content.instance.title}
                            <span className={"text-xs text-gray-400"}>
                                {content.instance.type.title}
                            </span>
                        </div>
                        <div className={"flex flex-row flex-wrap space-x-4"}>
                            {content.instance.website && (
                                <a
                                    href={content.instance.website}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    <TbWorld className={"w-4 h-4"}/>
                                </a>
                            )}
                            {content.instance.socialNetwork.map(item=>{
                                if(item.title === 'vkontakte') {
                                    return (
                                        <a
                                            href={content.instance.website}
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                        >
                                            <SlSocialVkontakte className={"w-4 h-4"}/>
                                        </a>
                                    )
                                } else if (item.title === 'telegram') {
                                    return (
                                        <a
                                            href={content.instance.website}
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                        >
                                            <LiaTelegramPlane className={"w-4 h-4"}/>
                                        </a>
                                    )
                                } else return null

                            })}
                        </div>
                    </div>
                )
            } else if (content.group === 'product') {
                setTitle(
                    <div className={"flex flex-col w-full"}>
                        <div>{content.instance.title}</div>
                        <div className={"badge badge-accent badge-outline"}>{t(`node.product.status.${content.instance.status}`)}</div>
                    </div>
                )
            } else if(content.group === 'speciality') {
                console.log(content.instance)
                setTitle(
                    <div className={"flex flex-col w-full space-y-2"}>
                        <div>{content.instance.title}</div>
                        <div className={"flex flex-row flex-wrap space-x-1"}>
                            <div className={"badge badge-accent badge-outline"}>
                                {t(`node.speciality.type.${content.instance.type.toLowerCase()}`)}
                            </div>
                            {content.instance.ages && (
                                <div className={"badge badge-accent badge-outline"}>
                                    {content.instance.ages}
                                </div>
                            )}
                        </div>
                    </div>
                )
            } else {
                setTitle(content.instance.title)
            }

        }
    }, [content]);

    return (
        <React.Fragment>
            <div
                ref={leftSideBarRef}
                className={"w-96 min-h-screen bg-base-200 flex flex-col pt-4 pb-10 overflow-y-scroll overflow-x-hidden"}

            >
                {content ? (
                    <>
                        {/*Title*/}
                        <NodeHeader
                            title={title}
                            group={content.group}
                            nodeId={content.instance.id}
                        />
                        <div className={"divider"} />
                        {/*Description*/}
                        {content.group !== 'person' && content.instance.description && (
                            <>
                                <NodeDescription
                                    description={content.instance.description}
                                />
                                <div className={"divider"} />
                            </>
                        )}
                        {/*Node specification*/}
                        {
                            {
                                person: <PersonNodeBody content={content} />,
                                expression: <ExpressionNodeBody content={content.instance.context} />,
                                event: <EventNodeBody content={content} />,
                                tool: <ToolNodeBody content={content} />,
                                product: <ProductNodeBody content={content} />,
                            }[content.group]
                        }
                        {/*Node relations*/}
                        {!isObjectEmpty(relationByGroup) && (
                            <>
                                <RelationSection relationByGroup={relationByGroup} />
                                <div className={"divider"} />
                            </>
                        )}
                        {/*Node media*/}
                        {media && media.length > 0 && (
                            <MediaSection media={media} />
                        )}
                    </>
                ) : (
                    <div className={"flex w-full h-screen items-center justify-center"}>
                        {t('title.selectElement')}
                    </div>
                )}

            </div>
        </React.Fragment>
    )
}

LeftSideBar.propTypes = {
    setOpenModal: PropTypes.func,
    setModalContent: PropTypes.func,
    setMediaInfoWidth: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setOpenModal: (state) => dispatch({ type: SET_OPEN_MODAL, payload: state }),
    setModalContent: (state) => dispatch({ type: SET_MODAL_CONTENT, payload: state }),
    setMediaInfoWidth: (width) => dispatch({ type: SET_MEDIA_INFO_WIDTH, payload: width })
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar)