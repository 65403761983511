import React, {useEffect, useState} from 'react';
import LegendTile from "./LegendTile";
import shortid from "shortid";
import PropTypes from "prop-types";
import {
    ADD_GROUP_TO_VISIBLE,
    REMOVE_GROUP_FROM_VISIBLE,
    SET_SELECTED_GROUP,
    SET_SELECTED_NODE_ID
} from "../../redux/actions/graph";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {defaultCheckedNodes} from "../../const";
import GroupControls from "./GroupControls";

const RightSideBar = ({
                          loadingGraph,
                          legend,
                          selectedNode,
                          setSelectedGroup,
                          setSelectedNodeId,
                          setNodeInfo,
                          visibleGroups,
                          addGroupToVisible,
                          removeGroupFromVisible
}) => {

    const { t } = useTranslation();

    const [group, setGroup] = useState('')

    useEffect(() => {
        if(selectedNode) {
            setGroup(selectedNode.group)
        }
    }, [selectedNode]);

    return (
        <React.Fragment >
            {legend ? (
                <div className="flex flex-col space-y-1">
                    {
                        legend.map(element => {

                            return (
                                <GroupControls
                                    key={element.code}
                                    addGroupToVisible={addGroupToVisible}
                                    removeGroupFromVisible={removeGroupFromVisible}
                                    group={group}
                                    setGroup={setGroup}
                                    visibleGroups={visibleGroups}
                                    elementCode={element.code}
                                    elementColor={element.color}
                                />
                            )
                        })
                    }
                    <button
                        className={"btn btn-outline btn-wide btn-sm"}
                        disabled={loadingGraph}
                        onClick={()=>{
                            setSelectedNodeId('')
                            setSelectedGroup('')
                            setGroup('')
                        }}
                    >{t('button.reset')}</button>
                </div>
            ) : (
                <div className={"text-lg"}>Выбирите элемент</div>
            )}
        </React.Fragment>
    )
}

RightSideBar.propTypes = {
    selectedGroup: PropTypes.string,
    selectedNodes: PropTypes.array,
    loadingGraph: PropTypes.bool,
    setSelectedGroup: PropTypes.func,
    setSelectedNodeId: PropTypes.func,
    setNodeInfo: PropTypes.func,
    addGroupToVisible: PropTypes.func,
    removeGroupFromVisible: PropTypes.func,

}

const mapStateToProps = (state) => ({
    selectedGroup: state.graph.selectedGroup,
    visibleGroups: state.graph.visibleGroups,
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedGroup: (group) => dispatch({ type: SET_SELECTED_GROUP, payload: group }),
    setSelectedNodeId: (id) => dispatch({ type: SET_SELECTED_NODE_ID, payload: id }),
    addGroupToVisible: (group) => dispatch({ type: ADD_GROUP_TO_VISIBLE, payload: group }),
    removeGroupFromVisible: (group) => dispatch({ type: REMOVE_GROUP_FROM_VISIBLE, payload: group })
})

export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar)