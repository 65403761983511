import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import ReadMoreModal from "./components/ReedMoreModal";
import ReadMoreLink from "./components/ReadMoreLink";
import {checkIsClamped} from "../../helpers";
import {SET_MODAL_CONTENT, SET_OPEN_MODAL} from "../../redux/actions/common";
import PropTypes from "prop-types";

const NodeTitle = ({ description, setModalContent, setOpenModal }) => {

    const { t } = useTranslation();
    const descriptionRef = useRef()

    const [isClamped, setIsClamped] = useState(false)

    useEffect(()=>{
        if(description && (descriptionRef.current !== undefined && descriptionRef.current !== null)){
            setIsClamped(checkIsClamped(descriptionRef))
        }
    },[description, descriptionRef])

    useEffect(() => {
        return () => {
            setModalContent(null)
            setOpenModal(false)
        }
    }, []);

    return (
       <React.Fragment>
           <>
               <div className={"flex flex-col px-4 "}>
                   <div className={"text-xl pb-4"}>{t('node.section.description.title')}</div>
                   <div ref={descriptionRef} className={"text-sm line-clamp-[10] pb-1"}>
                       {description}
                   </div>

                   {isClamped && (
                       <ReadMoreLink content={description} setModalContent={setModalContent} setOpenModal={setOpenModal} />
                   )}
               </div>
           </>
       </React.Fragment>
    )
}



NodeTitle.propTypes = {
    setOpenModal: PropTypes.func,
    setModalContent: PropTypes.func
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    setOpenModal: (state) => dispatch({ type: SET_OPEN_MODAL, payload: state }),
    setModalContent: (state) => dispatch({ type: SET_MODAL_CONTENT, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeTitle)