import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_SELECTED_NODE_ID} from "../../../redux/actions/graph";
import {useTranslation} from "react-i18next";
import {defaultStyles, FileIcon} from "react-file-icon";
import shortid from "shortid";
import {MdWorkOutline} from "react-icons/md";
import ReadMoreLink from "./ReadMoreLink";

const MediaTile = ({ instance, mediaInfoWidth }) => {

    const [mediaUrl, setMediaUrl] = useState('')
    const [mediaExtension, setMediaExtension] = useState('')
    const [showToolTip, setShowToolTip] = useState(false)

    useEffect(() => {
        return () => {
            setMediaExtension('')
            setMediaUrl('')
        }
    }, []);

    useEffect(() => {
        if(instance){
            setMediaUrl(instance.url)
        }
    }, [instance]);

    useEffect(()=>{
        if(mediaUrl !== ''){
            setMediaExtension(mediaUrl.split('.').pop())
        }
    },[mediaUrl])

    return (
        <div key={shortid.generate()} className="w-full flex flex-row justify-between items-start">
            <a
                className={"w-full flex flex-row space-x-4 items-center"}
                href={`${process.env.REACT_APP_API_URL}${mediaUrl}`}
                target={'_blank'}
                rel="noreferrer"
            >
                <span className={"w-full inline-flex items-baseline"}>
                    <div className={"self-start mr-3 w-8"}>
                        <FileIcon extension={mediaExtension} {...defaultStyles[mediaExtension]}/>
                    </div>
                    <span className={"text-xs w-11/12"}>
                        {instance.title}
                    </span>
                </span>

                {/*<div className={`flex tooltip tooltip-accent tooltip-right ${showToolTip ? "tooltip-open": ''}`} data-tip={instance.description}>*/}
                {/*    */}
                {/*</div>*/}

            </a>
            {instance.description && (
                <div className={`ml-1 dropdown dropdown-end ${showToolTip ? 'dropdown-open' : ''}`}>
                    <svg
                        tabIndex={0} xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="w-4 h-4 stroke-current text-info"
                        onMouseOver={()=>setShowToolTip(true)}
                        onMouseLeave={()=> setShowToolTip(false)}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div
                        tabIndex={0}
                        className="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box"
                        style={{width: mediaInfoWidth}}
                    >
                        <div className="card-body">
                            <p className={"text-xs"}>{instance.description}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}



MediaTile.propTypes = {
    mediaInfoWidth: PropTypes.number,
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
    mediaInfoWidth: state.common.mediaInfoWidth
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaTile)