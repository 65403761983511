import React, {useEffect, useState} from 'react';
import LegendTile from "./LegendTile";
import shortid from "shortid";
import PropTypes from "prop-types";
import {
    ADD_GROUP_TO_VISIBLE,
    REMOVE_GROUP_FROM_VISIBLE,
    SET_SELECTED_GROUP,
    SET_SELECTED_NODE_ID
} from "../../redux/actions/graph";
import {connect} from "react-redux";


const GroupControls = ({
                           visibleGroups,
                           addGroupToVisible,
                           removeGroupFromVisible,
                           elementCode,
                           elementColor,
                           group,
                           setGroup,
                           loadingGraph
}) => {

    const [checked, setChecked] = useState(false)

    const handleGroupVisibility = (checked, group) => {
        if(checked){
            addGroupToVisible(group)
        } else {
            removeGroupFromVisible(group)
        }
    }

    useEffect(() => {
        setChecked(visibleGroups.includes(elementCode))
    }, [visibleGroups]);

    return (
        <div
            className={"flex flex-row justify-between items-center space-x-3"}
        >
            <LegendTile
                color={elementColor}
                code={elementCode}
                group={group}
                setGroup={setGroup}
                disabled={!checked || loadingGraph === true}
            />
            <input
                type="checkbox"
                disabled={loadingGraph}
                className="checkbox checkbox-sm"
                checked={checked}
                onChange={(event)=>
                    handleGroupVisibility(event.target.checked, elementCode)}
            />
        </div>
    )
}

GroupControls.propTypes = {
    selectedGroup: PropTypes.string,
    selectedNodes: PropTypes.array,
    loadingGraph: PropTypes.bool,
    setSelectedGroup: PropTypes.func,
    setSelectedNodeId: PropTypes.func,
    setNodeInfo: PropTypes.func,
    addGroupToVisible: PropTypes.func,
    removeGroupFromVisible: PropTypes.func
}

const mapStateToProps = (state) => ({
    selectedGroup: state.graph.selectedGroup,
    visibleGroups: state.graph.visibleGroups,
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedGroup: (group) => dispatch({ type: SET_SELECTED_GROUP, payload: group }),
    setSelectedNodeId: (id) => dispatch({ type: SET_SELECTED_NODE_ID, payload: id }),
    addGroupToVisible: (group) => dispatch({ type: ADD_GROUP_TO_VISIBLE, payload: group }),
    removeGroupFromVisible: (group) => dispatch({ type: REMOVE_GROUP_FROM_VISIBLE, payload: group })
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupControls)