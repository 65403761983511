import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {checkIsClamped} from "../../helpers";
import ReadMoreLink from "./components/ReadMoreLink";
import PropTypes from "prop-types";
import {SET_MODAL_CONTENT, SET_OPEN_MODAL} from "../../redux/actions/common";
import {connect} from "react-redux";


const ExpressionNodeBody = ({ content, setModalContent, setOpenModal }) => {

    const { t } = useTranslation();
    const contextRef = useRef()

    const [isClamped, setIsClamped] = useState(false)

    useEffect(()=>{
        if(content && (contextRef.current !== undefined && contextRef.current !== null)){
            setIsClamped(checkIsClamped(contextRef))
        }
    },[content, contextRef])

    return (
        <React.Fragment >
            <div className={"px-4"}>
                <div className={"text-xl pb-4"}>{t('node.section.context.title')}</div>
                <div ref={contextRef} className={"text-sm line-clamp-[10] pb-1"}>
                    {content}
                </div>
                {isClamped && (
                    <ReadMoreLink content={content} setModalContent={setModalContent} setOpenModal={setOpenModal} />
                )}
            </div>
            <div className={"divider"} />
        </React.Fragment>
    )
}

ExpressionNodeBody.propTypes = {
    setOpenModal: PropTypes.func,
    setModalContent: PropTypes.func
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    setOpenModal: (state) => dispatch({ type: SET_OPEN_MODAL, payload: state }),
    setModalContent: (state) => dispatch({ type: SET_MODAL_CONTENT, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpressionNodeBody)