import axios from "axios";
import {store} from '../store';

const client = (baseURL) => {

    const instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        xsrfHeaderName: "X-CSRFTOKEN",
        xsrfCookieName: "csrftoken"
    });

    instance.interceptors.request.use(
        (config) => {
            const token = store.getState().auth.accessToken;

            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (err.response) {
                if (err.response.status === 401 && !originalConfig._retry) {
                    window.location.replace('/logout');
                }

                if (err.response.status === 403  && !originalConfig._retry) {
                    originalConfig._retry = true;
                }
                // return Promise.reject(err.response.data);
            }

            return Promise.reject(err);
        }
    );

    return instance;
}

export default client;
