import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const NodeCard = ({ node }) => {

    const { t } = useTranslation();

    if (node) {
        return (
            <div className="z-[1] card bg-white w-96 absolute top-3 left-3 bordered p-0 shadow">
                <div className="card-body p-4">
                    <h2 className="card-title text-sm">{t(`node.${node.group}.name`)}</h2>
                    <p className={"text-sm"}>{node.label}</p>
                    <p className={"text-xs"}>{node.instance.description}</p>
                </div>
            </div>
        )
    } else return null
}

NodeCard.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeCard)