import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_SELECTED_NODE_ID} from "../../../redux/actions/graph";
import {
    FiAlertTriangle,
    FiCalendar,
    FiMessageSquare,
    FiSettings,
    FiShoppingCart,
    FiSliders, FiTool,
    FiUsers
} from "react-icons/fi";
import {AiOutlineExperiment} from "react-icons/ai";
import {GoOrganization} from "react-icons/go";

const RelationTile = ({ instance, category, setSelectedNodeId }) => {

    const [title, setTitle] = useState('')

    useEffect(()=>{
        if(instance && category){
            if(category === 'person'){
                setTitle(instance.lastName && instance.firstName ? `${instance.lastName} ${instance.firstName}` : instance.email)
            } else if(category === 'expression'){
                setTitle(instance.type.title)
            } else {
                setTitle(instance.title)
            }
        }
    }, [category, instance])

    return (
        <li className={"w-full space-x-1"} key={title} onMouseEnter={()=>{
            const id = instance.id.replaceAll('-', '')
            setSelectedNodeId(id)
        }}>
            <a className={"w-full"}><p className={"w-full break-words whitespace-normal"}>{title}</p></a>
        </li>
    )
}



RelationTile.propTypes = {
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(RelationTile)