import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import {useTranslation} from "react-i18next";
import shortid from "shortid";
import {legend} from "../../const";

const SearchInput = ({ graphData, setSelectedNodeId, loadingGraph }) => {

    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([])

    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    const handleClick = e => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisible(false);
    };

    const searchFilter = (searchValue, list, searchBy = 'label') => {
        let lowerCaseQuery = searchValue.toLowerCase();
        return searchValue ? list.filter(x => x[searchBy].toLowerCase().includes(lowerCaseQuery)) : list;
    };

    const handleChange = e => {
        setSearchValue(e.target.value);
        if (!visible && e.target.value.length > 2) {
            setVisible(true);
        } else if (visible && e.target.value.length < 3) {
            setVisible(false)
        }
    };

    const selectItem = item => {
        setSearchValue(item.label);
        setSelectedNodeId(item.id);
        setVisible(false);
    };

    useEffect(()=>{
        if(graphData){
            setSearchResult(searchFilter(searchValue, graphData.nodes))
        }
    }, [graphData, searchValue])

    return (
        <div ref={dropdownRef} className={`w-full dropdown dropdown-bottom dropdown-end ${visible ? 'dropdown-open' : ''}`}>
            <input
                className="w-full input input-bordered input-sm"
                type="text"
                placeholder={t('navbar.search.placeholder')}
                value={searchValue}
                onChange={handleChange}
                disabled={loadingGraph}
            />

            {visible && (
                <ul className={"mt-2 w-full p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box"}>
                    {graphData &&
                        <li className={""}>
                            {
                                searchResult.length > 0 ? (
                                    searchResult.map((x, index) => {
                                        if (index < 10) {
                                            return <div
                                                key={shortid.generate()} onClick={() => selectItem(x)}
                                                className={"text-xs"}
                                            >
                                                {x.label}
                                                <div className="badge border-none"
                                                     style={{
                                                         backgroundColor: legend.find(item => item.code === x.group).color}}
                                                >
                                                    <span className={"text-xs"}>{t(`node.${x.group}.name`)}</span>
                                                </div>
                                            </div>
                                        } else if (index === 10) {
                                            return <div key={shortid.generate()}>...</div>
                                        } else return null
                                    })
                                ) : (
                                    <div key={shortid.generate()}>{t('navbar.search.no_results')}</div>
                                )
                            }
                        </li>
                    }
                </ul>
            )}
        </div>
    );
};

SearchInput.propTypes = {
    graphData: PropTypes.object,
    loadingGraph: PropTypes.bool,
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
    graphData: state.graph.graphData,
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput)