import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_OPEN_MODAL} from "../../../redux/actions/common";
import {useTranslation} from "react-i18next";

const ReadMoreModal = ({ text, openModal, setOpenModal }) => {

    const { t } = useTranslation();

    return (
        <dialog className={`bg-white modal modal-middle overflow-y-hidden ${openModal ? 'modal-open': ''}`}>
            <div className="relative modal-box p-0 w-full">
                <div className={"sticky top-0 right-2 px-4 py-5 flex justify-end items-center text-base"} style={{backgroundColor: 'white'}}>
                    <button
                        className="btn btn-sm btn-circle btn-ghost absolute top-2 right-2"
                        onClick={()=>setOpenModal(false)}
                    >
                        ✕
                    </button>
                </div>
                <div className={"flex flex-col px-4 pb-5 overflow-y-scroll overscroll-contain"}>
                    <p className="py-4">{text}</p>
                </div>

            </div>
        </dialog>
    )
}



ReadMoreModal.propTypes = {
    setOpenModal: PropTypes.func,
    text: PropTypes.string,
    openModal: PropTypes.bool
}

const mapStateToProps = (state) => ({
    openModal: state.common.openModal,
    text: state.common.content
})

const mapDispatchToProps = (dispatch) => ({
    setOpenModal: (state) => dispatch({ type: SET_OPEN_MODAL, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(ReadMoreModal)