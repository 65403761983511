import React, {useEffect, useState} from 'react';
import moment from "moment";
import {FiClock, FiImage, FiMapPin, FiVideo, FiYoutube} from "react-icons/fi";
import shortid from "shortid";
import PropTypes from "prop-types";
import {SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import {connect} from "react-redux";
import {RiTeamLine} from "react-icons/ri";
import {useTranslation} from "react-i18next";

const EventNodeBody = ({ content, setSelectedNodeId }) => {

    const { t } = useTranslation();

    const [eventTime, setEventTime] = useState(null)
    const [place, setPlace] = useState(null)
    const [team, setTeam] = useState([])
    const [videoUrl, setVideoUrl] = useState('')
    const [photosUrl, setPhotosUrl] = useState('')

    useEffect(()=>{
        const startDate = moment(content.instance?.startDate).format('DD-MM-YYYY')
        const endDate = moment(content.instance?.startDate).format('DD-MM-YYYY')

        setEventTime(`${startDate} - ${endDate}`)
        setPlace(content.instance.place)
        setTeam(content.instance.team)

        if(content.instance.videoClip){
            setVideoUrl(content.instance.videoClip)
        }

        if(content.instance.photoGallery){
            setPhotosUrl(content.instance.photoGallery)
        }

    }, [content])

    return (
        <React.Fragment >
            <div className={"flex flex-col px-4 space-y-2"}>
                {eventTime && (
                    <div className={"flex flex-row flex-wrap items-center space-x-2"}>
                        <FiClock />
                        <span className={"text-sm"}>{eventTime}</span>
                    </div>
                )}
                {place && (
                    <div className={"flex flex-row flex-wrap items-center space-x-2"}>
                        <FiMapPin />
                        <span className={"text-sm"}>{place}</span>
                    </div>
                )}
            </div>
            {team.length > 0 && (
                <>
                    <ul className="pl-0 pb-0 w-full menu bg-base-200 ounded-box space-x-1">
                        <li className={"w-full space-x-1"} key={shortid.generate()}>
                            <details className={"w-full"}>
                                <summary
                                    className={`custom-summary w-full active items-center space-x-1 bg-transparent hover:bg-transparent`}
                                >
                                    <RiTeamLine />
                                    {t('node.event.team')}
                                </summary>
                                <ul className={"space-x-1"}>
                                    {team.map(teammate => {

                                        return (

                                            <li
                                                className={"w-full space-x-1"}
                                                key={shortid.generate()}
                                                onMouseEnter={()=>{
                                                    const id = teammate.id.replaceAll('-', '')
                                                    setSelectedNodeId(id)
                                                }}
                                            >
                                                <a
                                                    className={"w-full"}>
                                                    <p className={"w-full break-words whitespace-normal"}>
                                                        {`${teammate.lastName} ${teammate.firstName}`}
                                                    </p>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </details>
                        </li>
                    </ul>
                </>
            )}
            <div className={"flex flex-col space-y-2 px-4 pt-3"}>
                {videoUrl !== '' && (
                    <a className={"btn btn-outline btn-sm"} href={videoUrl} target={'_blank'} rel={"noreferrer"}>
                        <FiYoutube className={"w-4 h-4"} />
                        summary video
                    </a>
                )}
                {photosUrl !== '' && (
                    <a className={"btn btn-outline btn-sm"}  href={photosUrl} target={'_blank'} rel={"noreferrer"}>
                        <FiImage className={"w-4 h-4"}/>
                        photo album
                    </a>
                )}
            </div>

            <div className={"divider"} />
        </React.Fragment>
    )
}

EventNodeBody.propTypes = {
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(EventNodeBody)