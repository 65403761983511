import LeftSideBar from "../components/leftSideBar";
import NavBar from "../components/navbar";
import RightSideBar from "../components/rightSideBar";
import {legend} from "../const";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GET_GRAPH} from "../redux/actions/graph";
import {connect} from "react-redux";
import Loading from "../components/Loading";
import ReadMoreModal from "../components/leftSideBar/components/ReedMoreModal";
import NetworkGraph from "../components/NetworkGraph";


const Graph = ({ getGraph, loadingGraph }) => {

    const [nodeInfo, setNodeInfo] = useState(null)

    const [cameraDistance, setCameraDistance] = useState(600)
    const [dimensional, setDimensional] = useState('3d')
    const [orbiting, setOrbiting] = useState(false)

    const zoomIn = () => {
        setCameraDistance(cameraDistance - 100)
    }

    const zoomOut = () => {
        setCameraDistance(cameraDistance + 100)
    }

    const handleOrbiting = (fgRef) => {
        setOrbiting(!orbiting)
    }

    const handleNodeInfo = (content) => {
        setNodeInfo(content)
    }

    useEffect(() => {
        getGraph()
    }, []);

    return (
        <div className="h-screen w-screen flex flex-row overflow-x-visible">
            <LeftSideBar content={nodeInfo}/>
            <div className={"flex flex-col w-full"}>
                <NavBar
                    setDimensional={setDimensional}
                    dimensional={dimensional}
                    orbiting={orbiting}
                    handleOrbiting={handleOrbiting}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                />
                {loadingGraph ? (
                    <div className={"flex flex-row justify-center items-center h-full"}>
                        <Loading isFullScreen={true} />
                    </div>
                ) : (
                    <NetworkGraph
                        orbiting={orbiting}
                        cameraDistance={cameraDistance}
                        dimensional={dimensional}
                        handleNodeInfo={handleNodeInfo}
                    />
                )}
            </div>
            <div className={"w-80 min-h-screen bg-base-200 flex flex-col px-4 pt-4"}>
                <RightSideBar
                    legend={legend}
                    selectedNode={nodeInfo}
                    setNodeInfo={setNodeInfo}
                />
            </div>
            <ReadMoreModal />
        </div>
    )
}

Graph.propTypes = {
    getGraph: PropTypes.func,
    loadingGraph: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    getGraph: () => dispatch({ type: GET_GRAPH })
})

export default connect(mapStateToProps, mapDispatchToProps)(Graph)