import { spawn } from 'redux-saga/effects'

// Sagas
import authSaga from "./auth";
import graphSaga from "./graph";

// Export the root saga
export default function* rootSaga() {
    yield spawn(authSaga)
    yield spawn(graphSaga)
}
