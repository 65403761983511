// Import the redux-saga/effects
import {
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    LOGOUT,
    LOGOUT_REQUESTED,
    REFRESH_TOKEN,
    REFRESH_TOKEN_REQUESTED,
} from "../actions/auth";

function* logOut() {
    yield put({ type: LOGOUT })
}

function* refreshToken({ payload }) {
    yield put({ type: REFRESH_TOKEN, payload: payload })
}

export default function* authSaga() {
    yield takeLatest(LOGOUT_REQUESTED, logOut)
    yield takeLatest(REFRESH_TOKEN_REQUESTED, refreshToken)
}
