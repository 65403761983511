import React, { useEffect, useState } from 'react';

const DynamicScriptLoader = ({ scriptUrls, children }) => {
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    useEffect(() => {
        // Функция для загрузки скриптов поочередно
        const loadScripts = async () => {
            for (const scriptUrl of scriptUrls) {
                await loadScript(scriptUrl);
            }
            // Все скрипты загружены
            setScriptsLoaded(true);
        };

        // Функция для загрузки одного скрипта
        const loadScript = (url) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = url;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        // Загрузка скриптов поочередно
        loadScripts();

        // Возвращаем функцию для удаления всех скриптов при размонтировании компонента
        return () => {
            for (const scriptUrl of scriptUrls) {
                const script = document.querySelector(`script[src="${scriptUrl}"]`);
                if (script) {
                    script.parentNode.removeChild(script);
                }
            }
        };
    }, [scriptUrls]);

    // Показываем контент только после загрузки всех скриптов
    if (scriptsLoaded) {
        return children;
    } else {
        return null; // Можно также показать индикатор загрузки
    }
};

export default DynamicScriptLoader;