import camelcaseKeys from "camelcase-keys";
import client from "./client";
import {apiUrl} from "../../const";

const accountClient = client(`${apiUrl}/api/v1`)

const getGraph = async () => {
    try {
        const response = await accountClient.get(`/graph/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const graphApi = {
    getGraph,
}

export default graphApi
