// ["problem","person","expression","event","tool","organisation","product","speciality","expressiontype","media","speechtopic","expertisearea","organisationtype","solutionpath","undefined"]
export const defaultVisibleNodes = [
    "problem",
    "person",
    "event",
    "tool",
    "organisation",
    "product",
    "speciality",
    "solutionpath",
]

export const legend = [
    {color: '#2eb5d1', code: 'problem'},
    {color: '#48d824', code: 'person'},
    {color: '#cc7733', code: 'expression'},
    {color: '#f4e804', code: 'event'},
    {color: '#f47069', code: 'tool'},
    {color: '#59d1a7', code: 'organisation'},
    {color: '#c66f27', code: 'product'},
    {color: '#8e003d', code: 'speciality'},
    {color: '#6f68f2', code: 'expressiontype'},
    {color: '#dd377c', code: 'media'},
    {color: '#16A085', code: 'speechtopic'},
    {color: '#D35400', code: 'expertisearea'},
    {color: '#34495E', code: 'organisationtype'},
    {color: '#000000', code: 'solutionpath'},
    {color: 'gray', code: 'undefined'},
]

// 'http://api.local.knotta.ru:8100'
export const apiUrl = process.env.NODE_ENV === 'development' ? 'https://admin.center.knotta.ru' : 'https://admin.center.knotta.ru'