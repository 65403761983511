import './App.css';

import {connect} from "react-redux";
import ToastProvider from "./context/ToastContext";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./screens/Main";
import {Suspense} from "react";
import Loading from "./components/Loading";
import {useMediaQuery} from "react-responsive";
import Mobile from "./screens/Mobile";

const loading = () => {
    return (
       <div className={"flex w-screen min-h-screen justify-center items-center"}>
           <Loading />
       </div>
    )
}

const App = () => {

    const isTMobile = useMediaQuery({ maxWidth: 720 })

    return (
        <Router>
            <Suspense fallback={loading()}>
                <ToastProvider>
                    {isTMobile ? (
                        <Mobile />
                    ) : (
                        <Main />
                    )}
                </ToastProvider>
            </Suspense>
        </Router>
  );
}

App.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
