import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import RelationSection from "./components/RelationSection";
import MediaSection from "./components/MediaSection";
import shortid from "shortid";
import {RiTeamLine} from "react-icons/ri";
import PropTypes from "prop-types";
import {SET_SELECTED_NODE_ID} from "../../redux/actions/graph";
import {connect} from "react-redux";


const ToolNodeBody = ({ content, setSelectedNodeId }) => {

    const { t } = useTranslation();

    const [authors, setAuthors] = useState([])

    useEffect(() => {
        console.log(content.instance)
        if(content && content.instance.authors !== null){
            setAuthors(content.instance.authors)
        }
    }, [content]);

    return (
        <React.Fragment >
            {authors.length > 0 && (
                <>
                    <ul className="pl-0 pb-0 w-full menu bg-base-200 ounded-box space-x-1">
                        <li className={"w-full space-x-1"} key={shortid.generate()}>
                            <details className={"w-full"}>
                                <summary
                                    className={`custom-summary w-full active items-center space-x-1 bg-transparent hover:bg-transparent`}
                                >
                                    <RiTeamLine />
                                    {t('node.tool.authors')}
                                </summary>
                                <ul className={"space-x-1"}>
                                    {authors.map(author => {

                                        return (

                                            <li
                                                className={"w-full space-x-1"}
                                                key={shortid.generate()}
                                                onMouseEnter={()=>{
                                                    const id = author.id.replaceAll('-', '')
                                                    setSelectedNodeId(id)
                                                }}
                                            >
                                                <a
                                                    className={"w-full"}>
                                                    <p className={"w-full break-words whitespace-normal"}>
                                                        {`${author.lastName} ${author.firstName}`}
                                                    </p>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </details>
                        </li>
                    </ul>
                </>
            )}
            <div className={"divider"} />
        </React.Fragment>
    )
}

ToolNodeBody.propTypes = {
    setSelectedNodeId: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNodeId: (data) => dispatch({ type: SET_SELECTED_NODE_ID, payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(ToolNodeBody)