import React, {useEffect, useRef, useState} from 'react';
import {FiMail, FiPhone} from "react-icons/fi";
import moment from "moment/moment";
import {LiaBirthdayCakeSolid} from "react-icons/lia";
import {useTranslation} from "react-i18next";
import {GoOrganization} from "react-icons/go";
import {MdWorkOutline} from "react-icons/md";
import {checkIsClamped} from "../../helpers";
import PropTypes from "prop-types";
import {SET_MODAL_CONTENT, SET_OPEN_MODAL} from "../../redux/actions/common";
import {connect} from "react-redux";
import ReadMoreLink from "./components/ReadMoreLink";


const PersonNodeBody = ({ content, setOpenModal, setModalContent }) => {

    const { t } = useTranslation();

    const [expertProfile, setExpertProfile] = useState(null)

    const jobTitleRef = useRef()

    const [isClamped, setIsClamped] = useState(false)

    useEffect(()=>{
        if(content.instance.jobTitle && (jobTitleRef.current !== undefined && jobTitleRef.current !== null)){
            setIsClamped(checkIsClamped(jobTitleRef))
        }
    },[content, jobTitleRef])

    useEffect(() => {
        return () => {
            setModalContent(null)
            setOpenModal(false)
        }
    }, []);

    useEffect(()=>{
        if(content && content.instance.expertProfile !== undefined) {
            setExpertProfile(content.instance.expertProfile)
        }
    }, [content, expertProfile])

    return (
        <React.Fragment >
            <div className={"flex flex-col px-4 space-y-3"}>
                {content.instance.jobTitle && (
                    <span className={"inline-flex items-baseline"}>
                        <MdWorkOutline className={"self-center mr-3"}/>
                        <span className={"flex flex-col w-11/12"}>
                            <span ref={jobTitleRef} className={"line-clamp-[3]"}>
                                {content.instance.jobTitle}
                            </span>
                            {isClamped && (
                                <ReadMoreLink
                                    content={content.instance.jobTitle}
                                    setModalContent={setModalContent}
                                    setOpenModal={setOpenModal
                                } />
                            )}
                        </span>
                    </span>
                )}

                {content.instance.organisation && (
                    <span className={"inline-flex items-baseline"}>
                        <GoOrganization className={"self-center mr-3"}/>
                        <span className={"w-11/12"}>
                            {content.instance.organisation.title}
                        </span>
                    </span>
                )}
                <div className={"space-y-1"}>
                    {expertProfile !== null && (
                        <>
                            {expertProfile.email && (
                                <div className={"flex flex-row items-center space-x-3"}>
                                    <FiMail />
                                    <a href={`mailto:${expertProfile.email}`} className={"btn-link"}><span>{expertProfile.email}</span></a>
                                </div>
                            )}
                            {expertProfile.phone && (
                                <div className={"flex flex-row items-center space-x-3"}>
                                    <FiPhone />
                                    <a href={`tel:${expertProfile.phone}`} className={"btn-link"}><span>{expertProfile.phone}</span></a>
                                </div>
                            )}
                            {expertProfile.birthday && (
                                <div className={"flex flex-row items-center space-x-3"}>
                                    <LiaBirthdayCakeSolid />
                                    <span>{moment(expertProfile?.birthday).format('DD.MM.YYYY')}</span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className={"divider"} />
        </React.Fragment>
    )
}

PersonNodeBody.propTypes = {
    setOpenModal: PropTypes.func,
    setModalContent: PropTypes.func
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    setOpenModal: (state) => dispatch({ type: SET_OPEN_MODAL, payload: state }),
    setModalContent: (state) => dispatch({ type: SET_MODAL_CONTENT, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonNodeBody)