import React from "react";
import PropTypes from "prop-types";
import {GET_GRAPH} from "../redux/actions/graph";
import {connect} from "react-redux";
import DynamicScriptLoader from "../components/DynamicScriptLoader";


const Mobile = () => {

    const scriptUrls = [
        'https://unpkg.com/aframe',
        'https://unpkg.com/aframe-forcegraph-component',
        'https://morandd.github.io/aframe-multitouch-look-controls/multitouch-look-controls.js'
    ];

    return (
        <div>
            <DynamicScriptLoader scriptUrls={scriptUrls}>
                <div className="min-h-screen w-screen flex flex-row">
                    <a-scene background="color: #002" stats>
                        <a-entity cursor="rayOrigin: mouse; mouseCursorStylesEnabled: true;" raycaster="objects: [forcegraph];"></a-entity>
                        <a-entity laser-controls="hand: left" raycaster="objects: [forcegraph]; lineColor: steelblue; lineOpacity: 0.85;"></a-entity>
                        <a-entity laser-controls="hand: right" raycaster="objects: [forcegraph]; lineColor: steelblue; lineOpacity: 0.85;"></a-entity>

                        <a-camera
                            look-controls="pointerLockEnabled: false"
                            wasd-controls="fly: true; acceleration: 600;"
                        >
                            <a-text
                                id="forcegraph-tooltip"
                                position="0 -1 -3"
                                width="2"
                                align="center"
                                color="lavender"
                            ></a-text>
                        </a-camera>

                        <a-entity
                            position="0 0 -100"
                            forcegraph="
                    json-url: https://admin.center.knotta.ru/api/v1/graph/;
                    node-auto-color-by: group;
                    link-directional-arrow-length: 1.5;
                    link-directional-arrow-rel-pos: 1;
                    on-node-hover: node => document.querySelector('#forcegraph-tooltip').setAttribute('value', node ? node.id : '');
                    on-node-click: node => document.querySelector('#forcegraph-tooltip').setAttribute('value', `Clicked on ${node.id}!`);"
                        ></a-entity>
                    </a-scene>

                </div>
            </DynamicScriptLoader>
        </div>
    )
}

Mobile.propTypes = {
    getGraph: PropTypes.func,
    loadingGraph: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingGraph: state.graph.loadingGraph
})

const mapDispatchToProps = (dispatch) => ({
    getGraph: () => dispatch({ type: GET_GRAPH })
})

export default connect(mapStateToProps, mapDispatchToProps)(Mobile)