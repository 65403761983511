import {
    LOGOUT,
    REFRESH_TOKEN,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,
    REGISTRATION_REQUEST,
    SEND_RESET_LINK_REQUEST,
    SEND_RESET_LINK_SUCCESS,
    SEND_RESET_LINK_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CLEAN_RESET_PASSWORD_DATA,
    CLEAN_SEND_LINK_DATA,
    CLEAN_REGISTRATION_DATA,
    NEED_REFRESH_TOKEN
} from "../actions/auth";

const initialState = {
    isLoggedIn: false,
    accessToken: null,
    registrationError: null,
    loadingRegistration: false,
    loadingLogin: false,
    loginError: null,
    loadingSendLink: false,
    sendLinkError: null,
    sendLinkDetail: null,
    loadingResetPassword: false,
    resetPasswordError: null,
    resetPasswordDetail: null,
    loginWithProvider: false,
    needRefreshToken: false
};

export default function auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        /* REGISTRATION */
        case REGISTRATION_REQUEST:
            return {
                ...state,
                loadingRegistration: true,
                isLoggedIn: false,
                registrationError: null
            };

        case REGISTRATION_SUCCESS:
            return {
                ...state,
                loadingRegistration: false,
                isLoggedIn: true,
                registrationError: null,
                accessToken: payload.accessToken,
            };

        case REGISTRATION_ERROR:
            return {
                ...state,
                loadingRegistration: false,
                isLoggedIn: false,
                registrationError: payload
            };

        case CLEAN_REGISTRATION_DATA:
            return {
                ...state,
                registrationError: null,
                loadingRegistration: false
            };

        /* SEND RESET LINK */
        case SEND_RESET_LINK_REQUEST:
            return {
                ...state,
                loadingSendLink: true,
                sendLinkError: null
            };

        case SEND_RESET_LINK_SUCCESS:
            return {
                ...state,
                loadingSendLink: false,
                sendLinkError: null,
                sendLinkDetail: payload.detail,
            };

        case SEND_RESET_LINK_ERROR:
            return {
                ...state,
                sendLinkDetail: initialState.sendLinkDetail,
                loadingSendLink: false,
                sendLinkError: null,
            };

        case CLEAN_SEND_LINK_DATA:
            return {
                ...state,
                loginError: null,
                sendLinkDetail: null,
                loadingSendLink: false,
            };

        /* RESET PASSWORD */
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loadingResetPassword: true,
                resetPasswordError: null
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingResetPassword: false,
                resetPasswordError: null,
                resetPasswordDetail: payload.detail,
            };

        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordDetail: initialState.sendLinkDetails,
                loadingResetPassword: false,
                resetPasswordError: null,
            };

        case CLEAN_RESET_PASSWORD_DATA:
            return {
                ...state,
                resetPasswordError: null,
                resetPasswordDetail: null,
                loadingResetPassword: false,
            };

        /* LOGOUT */
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: initialState.isLoggedIn,
                loginWithProvider: false,
                loadingLogin: false,
                accessToken: initialState.accessToken,
            };

        /* REFRESH TOKEN */
        case REFRESH_TOKEN:
            return {
                ...state,
                accessToken: payload,
            };

        case NEED_REFRESH_TOKEN:
            return {
                ...state,
                needRefreshToken: payload,
            };

        default:
            return state;
    }
}
