import {SET_MEDIA_INFO_WIDTH, SET_MODAL_CONTENT, SET_OPEN_MODAL} from "../actions/common";

const initialState = {
    openModal: false,
    content: null,
    mediaInfoWidth: 0
}

export default function commonReducer(state = initialState, {type, payload}) {
    switch(type) {

        case SET_OPEN_MODAL: {
            return {
                ...state,
                openModal: payload,
            }
        }

        case SET_MODAL_CONTENT:

            return {
                ...state,
                content: payload,
            }

        case SET_MEDIA_INFO_WIDTH:

            return {
                ...state,
                mediaInfoWidth: payload,
            }

        default:
            return state
    }
}
